import React, { FormEvent, useMemo, useState } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import close from '../../../assets/svg/close.svg';
import apple_pay_dark from '../../../assets/svg/apple_pay_dark.svg';
import apple_pay_white from '../../../assets/svg/apple_pay_white.svg';
import credit_card_pay from '../../../assets/svg/credit_card_pay.svg';
import { PaymentMethodType } from '@providers/QuizProvider/QuizProvider.types';
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardNumberElementChangeEvent,
  CardYearElement,
} from '@recurly/react-recurly';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { EMAIL_REGEX } from '@utils/constants';
import { plansUtils } from '@services/planUtils';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';

function PaymentModal() {
  const { setQuizData, quizData } = useQuizState();

  const {
    handlePayByApple,
    handleSubmitForm,
    formRef,
    paymentFormError,
    applePayError,
    isReadyAppleButton,
    isPaymentLoading,
  } = usePaymentState();

  const { selectedPlan } = usePaymentConfig();

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidCardMonth, setValidCardMonth] = useState(false);
  const [isValidCardYear, setValidCardYear] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [isValidFirstName, setValidFirstName] = useState(false);
  const [isValidLastName, setValidLastName] = useState(false);
  const [isValidEmail, setValidEmail] = useState(
    Boolean(quizData.email.toLowerCase().match(EMAIL_REGEX))
  );
  const [showErrors, setShowErros] = useState(false);
  const [isDiscoverCard, setDiscoverCard] = useState(false);

  const inputStyle = {
    fontColor: '#282624',
    fontSize: '16px',
    fontFamily: 'Source Sans Pro',
    lineHeight: 'normal',
    fontWeight: '500',
    backgroundColor: 'white',
  };

  const handleSubmitClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isPaymentLoading) return;

    const isValid =
      !isDiscoverCard &&
      isValidEmail &&
      isValidFirstName &&
      isValidLastName &&
      isValidCardNumber &&
      isValidCardMonth &&
      isValidCardYear &&
      isValidCardCvv;
    if (isValid) {
      handleSubmitForm(event);
    } else {
      setShowErros(true);
    }
  };

  const handleAppleButtonClick = () => {
    if (isPaymentLoading) return;

    if (isValidEmail) {
      handlePayByApple();
    } else {
      setShowErros(true);
    }
  };

  const selectedPlanUiInfo = useMemo(() => {
    if (!selectedPlan) return null;

    const length = selectedPlan.trial_length
      ? selectedPlan.trial_length
      : selectedPlan.length;

    const planName = plansUtils.getPlaneName(
      length,
      Boolean(selectedPlan.trial_length)
    );

    const trialValue =
      selectedPlan.trial_length !== null
        ? selectedPlan.trial_sum
        : selectedPlan.sum;

    const discountValue = plansUtils.getPriceWithDiscount(
      trialValue,
      selectedPlan.discount_percent ?? 30
    );

    return {
      planName,
      discountValue,
      trialValue,
    };
  }, [selectedPlan]);

  function checkForDiscoverCard(event: CardNumberElementChangeEvent) {
    const discoverCards = [6011, 644, 645, 646, 647, 648, 649, 65];
    if (event.valid && event.length === 16) {
      const isDiscover = discoverCards.some((cardNumber) => {
        return event.firstSix.startsWith(cardNumber.toString());
      });
      setDiscoverCard(isDiscover);
    } else {
      setDiscoverCard(false);
    }
  }

  return (
    <Modal
      open={quizData.isPaymentModalOpen}
      onClose={() => {
        setQuizData((prev) => ({ ...prev, isPaymentModalOpen: false }));
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 40px)',
            maxWidth: '460px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '20px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              onClick={() => {
                setQuizData((prev) => ({ ...prev, isPaymentModalOpen: false }));
              }}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
              }}
              src={close}
              alt="close"
            ></img>
            <Typography
              sx={{
                color: '#282624',
                fontFamily: 'Avenir600',
                fontSize: '20px',
                padding: '15px',
                borderBottom: '1px solid rgba(233, 232, 231, 1)',
              }}
            >
              Payment
            </Typography>
            <Box
              sx={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  width: '100%',
                  '& div': {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '56px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  },
                }}
              >
                {isReadyAppleButton && (
                  <Box
                    onClick={() => {
                      setQuizData((prev) => ({
                        ...prev,
                        selectedPaymentMethod: PaymentMethodType.APPLE,
                      }));
                    }}
                    sx={{
                      outline:
                        quizData.selectedPaymentMethod ===
                        PaymentMethodType.APPLE
                          ? '2px solid #ED744E'
                          : '1px solid #E9E8E7',
                    }}
                  >
                    <img src={apple_pay_dark} alt="apple pay"></img>
                  </Box>
                )}
                <Box
                  onClick={() => {
                    setQuizData((prev) => ({
                      ...prev,
                      selectedPaymentMethod: PaymentMethodType.CARD,
                    }));
                  }}
                  sx={{
                    outline:
                      quizData.selectedPaymentMethod === PaymentMethodType.CARD
                        ? '2px solid #ED744E'
                        : '1px solid #E9E8E7',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img src={credit_card_pay} alt="credit card pay"></img>
                  <Typography
                    sx={{
                      color: '#282624',
                      fontFamily: 'Avenir600',
                      fontSize: '12px',
                    }}
                  >
                    Credit card
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  '& div': {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: '#282624',
                      fontFamily: 'Avenir400',
                      fontSize: '14px',
                      textAlign: 'left',
                      marginRight: '10px',
                    }}
                  >
                    Your{' '}
                    <span
                      style={{
                        fontFamily: 'Avenir600',
                      }}
                    >
                      {selectedPlanUiInfo?.planName}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: '#282624',
                      fontFamily: 'Avenir600',
                      fontSize: '14px',
                      textAlign: 'right',
                    }}
                  >
                    ${selectedPlanUiInfo?.trialValue}
                    {/*<span*/}
                    {/*  style={{*/}
                    {/*    color: '#918c8a',*/}
                    {/*    fontFamily: 'Avenir400',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  per day*/}
                    {/*</span>*/}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '1px',
                    background: '#E9E8E7',
                    margin: '5px 0',
                  }}
                ></Box>
                <Box>
                  <Typography
                    sx={{
                      color: '#282624',
                      fontFamily: 'Avenir600',
                      fontSize: '14px',
                      textAlign: 'left',
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      color: '#918c8a',
                      fontFamily: 'Avenir400',
                      fontSize: '14px',
                      textAlign: 'right',
                    }}
                  >
                    <span
                      style={{
                        color: '#ED744E',
                        fontFamily: 'Avenir600',
                        fontSize: '14px',
                        textDecoration: 'line-through',
                      }}
                    >
                      ${selectedPlan?.sum}
                    </span>{' '}
                    ${selectedPlan?.trial_sum}
                  </Typography>
                </Box>
              </Box>

              {quizData.selectedPaymentMethod === PaymentMethodType.APPLE ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      '& input': {
                        width: 'calc(100%)',
                        borderRadius: '8px',
                        border: '1px solid rgba(143, 154, 203, 0.20)',
                        paddingLeft: '15px',
                        height: '48px',
                        boxSizing: 'border-box',
                        color: '#282624',
                        fontFamily: 'Avenir500',
                        fontSize: '14px',
                        outline: 'none',
                        '&:hover': {
                          outline: 'none',
                        },
                      },
                      '& .input-title': {
                        color: '#282624',
                        fontFamily: 'Avenir500',
                        fontSize: '14px',
                        textAlign: 'left',
                        marginBottom: '5px',
                      },
                      '& .error': {
                        width: '100%',
                        color: '#E03045',
                        fontFamily: 'Avenir400',
                        fontSize: '10px',
                        textAlign: 'left',
                        marginTop: '5px',
                      },
                    }}
                  >
                    <Typography className="input-title">Email</Typography>
                    <input
                      type="email"
                      value={quizData.email}
                      onChange={(event) => {
                        setShowErros(false);
                        const isValid = event.target.value
                          .toLowerCase()
                          .match(EMAIL_REGEX);
                        setValidEmail(Boolean(isValid));
                        setQuizData((prev) => ({
                          ...prev,
                          email: event.target.value,
                        }));
                      }}
                    ></input>
                    {showErrors && !isValidEmail && (
                      <Typography className="error">
                        Please enter a valid email
                      </Typography>
                    )}
                  </Box>
                  {isReadyAppleButton && applePayError && (
                    <Typography
                      sx={{
                        marginBottom: '20px',
                        color: '#E03045',
                        fontFamily: 'Avenir500',
                        fontSize: '14px',
                      }}
                    >
                      {applePayError.message}
                    </Typography>
                  )}

                  <QuizButton
                    disabled={!isReadyAppleButton}
                    onClick={handleAppleButtonClick}
                    variant={ButtonType.APPLE}
                    text=""
                  >
                    {isPaymentLoading ? (
                      <CircularProgress
                        style={{ color: 'white' }}
                      ></CircularProgress>
                    ) : (
                      <img src={apple_pay_white} alt="apple pay"></img>
                    )}
                  </QuizButton>
                </>
              ) : (
                <>
                  <form
                    onSubmit={handleSubmitClick}
                    ref={formRef}
                    onChange={() => {
                      setShowErros(false);
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '1px',
                        background: '#E9E8E7',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        margin: '20px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        '& .wrapper': {
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        },
                        '& input': {
                          width: 'calc(100%)',
                          borderRadius: '8px',
                          border: '1px solid rgba(143, 154, 203, 0.20)',
                          paddingLeft: '15px',
                          height: '48px',
                          boxSizing: 'border-box',
                          color: '#282624',
                          fontFamily: 'Avenir500',
                          fontSize: '14px',
                          outline: 'none',
                          '&:hover': {
                            outline: 'none',
                          },
                        },
                        '& .input-title': {
                          color: '#282624',
                          fontFamily: 'Avenir500',
                          fontSize: '14px',
                          textAlign: 'left',
                          marginBottom: '5px',
                        },
                        '& .error': {
                          width: '100%',
                          color: '#E03045',
                          fontFamily: 'Avenir400',
                          fontSize: '10px',
                          textAlign: 'left',
                          marginTop: '5px',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <Box className="wrapper">
                          <Typography className="input-title">
                            First name
                          </Typography>
                          <input
                            type="text"
                            data-recurly="first_name"
                            onChange={(event) => {
                              setValidFirstName(
                                event.target.value.trim() !== ''
                              );
                            }}
                          ></input>
                          {showErrors && !isValidFirstName && (
                            <Typography className="error">
                              Fill in the field
                            </Typography>
                          )}
                        </Box>
                        <Box className="wrapper">
                          <Typography className="input-title">
                            Last name
                          </Typography>
                          <input
                            type="text"
                            data-recurly="last_name"
                            onChange={(event) => {
                              setValidLastName(
                                event.target.value.trim() !== ''
                              );
                            }}
                          ></input>
                          {showErrors && !isValidLastName && (
                            <Typography className="error">
                              Fill in the field
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box className="wrapper">
                        <Typography className="input-title">Email</Typography>
                        <input
                          type="email"
                          value={quizData.email}
                          onChange={(event) => {
                            const isValid = event.target.value
                              .toLowerCase()
                              .match(EMAIL_REGEX);
                            setValidEmail(Boolean(isValid));
                            setQuizData((prev) => ({
                              ...prev,
                              email: event.target.value,
                            }));
                          }}
                        ></input>
                        {showErrors && !isValidEmail && (
                          <Typography className="error">
                            Please enter a valid email
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        background: '#F9E9E0',
                        borderRadius: '12px',
                        marginBottom: '20px',
                        padding: '20px',
                        '& .input-wrapper': {
                          background: 'white',
                          borderRadius: '8px',
                          maxHeight: '48px',
                          paddingLeft: '15px',
                          '& iframe': {
                            maxHeight: '48px',
                          },
                        },
                        '& .input-title': {
                          color: '#282624',
                          fontFamily: 'Avenir500',
                          fontSize: '14px',
                          textAlign: 'left',
                          marginBottom: '5px',
                        },
                        '& .error': {
                          width: '100%',
                          color: '#E03045',
                          fontFamily: 'Avenir400',
                          fontSize: '10px',
                          textAlign: 'left',
                          marginTop: '5px',
                        },
                      }}
                    >
                      <Typography className="input-title">
                        Card number
                      </Typography>
                      <Box className="input-wrapper">
                        <CardNumberElement
                          style={{
                            ...inputStyle,
                            placeholder: {
                              content: 'XXXX XXXX XXXX XXXX',
                              color: 'rgba(144, 140, 137, 0.5)',
                            },
                          }}
                          inputType={'text'}
                          onChange={(event) => {
                            setValidCardNumber(event.valid);
                            checkForDiscoverCard(event);
                          }}
                        />
                      </Box>
                      {showErrors && !isValidCardNumber && (
                        <Typography className="error">
                          Your card number is incomplete
                        </Typography>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          gap: '20px',
                          marginTop: '15px',
                        }}
                      >
                        <Box>
                          <Typography className="input-title">
                            Expires on
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '9px',
                            }}
                          >
                            <Box>
                              <Box className="input-wrapper">
                                <CardMonthElement
                                  style={{
                                    ...inputStyle,
                                    placeholder: {
                                      content: 'MM',
                                      color: 'rgba(47, 42, 42, 0.2)',
                                    },
                                  }}
                                  onChange={(event) => {
                                    setValidCardMonth(event.valid);
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box className="input-wrapper">
                              <CardYearElement
                                style={{
                                  ...inputStyle,
                                  placeholder: {
                                    content: 'YY',
                                    color: 'rgba(47, 42, 42, 0.2)',
                                  },
                                }}
                                onChange={(event) => {
                                  setValidCardYear(event.valid);
                                }}
                              />
                            </Box>
                          </Box>
                          {showErrors &&
                            (!isValidCardMonth || !isValidCardYear) && (
                              <Typography className="error" style={{}}>
                                Your card is expired
                              </Typography>
                            )}
                        </Box>
                        <Box>
                          <Typography className="input-title">CVC</Typography>
                          <Box className="input-wrapper">
                            <CardCvvElement
                              style={{
                                ...inputStyle,
                                placeholder: {
                                  content: '•••',
                                  color: 'rgba(144, 140, 137, 0.5)',
                                },
                              }}
                              onChange={(event) => {
                                setValidCardCvv(event.valid);
                              }}
                            />
                          </Box>
                          {showErrors && !isValidCardCvv && (
                            <Typography className="error">
                              Your card’s security code is incompete
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {paymentFormError && (
                      <Typography
                        sx={{
                          marginBottom: '20px',
                          color: '#E03045',
                          fontFamily: 'Avenir500',
                          fontSize: '14px',
                        }}
                      >
                        {paymentFormError}
                      </Typography>
                    )}
                    {isDiscoverCard && (
                      <Typography
                        sx={{
                          marginBottom: '20px',
                          color: '#E03045',
                          fontFamily: 'Avenir500',
                          fontSize: '14px',
                        }}
                      >
                        Discover cards are not accepted.
                      </Typography>
                    )}
                    <QuizButton
                      type="submit"
                      onClick={() => {}}
                      variant={ButtonType.ORANGE}
                      text={isPaymentLoading ? '' : 'Proceed payment'}
                    >
                      {isPaymentLoading ? (
                        <CircularProgress
                          style={{ color: 'white' }}
                        ></CircularProgress>
                      ) : undefined}
                    </QuizButton>
                  </form>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default PaymentModal;
