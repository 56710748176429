import { Box, Link, Typography } from '@mui/material';

import footerImage from '../../../assets/images/subscription/free-person-footer.webp';
import camp from '../../../assets/svg/subscription/camp.svg';

import { Routes } from '../../../App';
import money_back from '../../../assets/svg/money_back.svg';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { APP_CONSTANTS } from '@utils/constants';
import { useMemo } from 'react';

function Footer() {
  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        overflow: 'hidden',
        '& .camp': {
          maxWidth: '100%',
          width: '100%',
        },
        '& .footer-image': {
          display: 'inline-block',
          maxWidth: '100%',
          width: '100%',
        },
      }}
    >
      <img draggable={false} src={camp} className="camp" alt="camp" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 20px 30px',
          backgroundColor: '#fff',
        }}
      >
        <img
          draggable={false}
          width={84}
          height={84}
          src={money_back}
          alt="money_back"
        ></img>
        <Typography
          sx={{
            fontFamily: 'Avenir500',
            fontSize: '24px',
            lineHeight: 'normal',
            textAlign: 'center',
            color: '#282624',
            userSelect: 'none',
            marginTop: '30px',
            marginBottom: '10px',
          }}
        >
          Money Back guarantee
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '14px',
            lineHeight: 'normal',
            textAlign: 'center',
            userSelect: 'none',
            color: '#282624',
            marginBottom: '30px',
          }}
        >
          With our trial, you`ll gain a comprehensive view of your cardiac
          health and the means to influence it - in no time positively! We are
          ready to return your money if you feel uncomfortable using the App.
          Find more about applicable limitations in our
        </Typography>
        <QuizButton
          onClick={() => {
            const plans = document.getElementById('get_your_plan');
            if (plans) {
              plans.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          variant={ButtonType.ORANGE}
          text="Get rid of addiction"
        ></QuizButton>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            textAlign: 'center',
            color: '#908C89',
            userSelect: 'none',
            marginTop: '30px',
            '& a':{
              color:'#ED744E'
            }
          }}
        >
          All rights reserved. 2024<br/>
          {APP_CONSTANTS.COMPANY_NAME}<br/>
          Room 1002, 10/F, Easey Commercial Building,<br/>
          253-261 Hennessy Road, Wan Chai, HK<br/>
          Tel: 00852-37854100, Fax: 00852-37854101.<br/>
          BRN No.: 76614857 
          <br/>
          <br/>
          <a href={'mailto:'+APP_CONSTANTS.EMAIL}>{APP_CONSTANTS.EMAIL}</a>
        </Typography>
        <Box
          sx={{
            height: '1px',
            margin: '15px 0',
            background: 'rgba(143, 154, 203, 0.20)',
            width: '100%',
          }}
        ></Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-around',
            '& .link': {
              userSelect: 'none',
              fontFamily: 'Avenir400',
              fontSize: '12px',
              color: '#7A82A6',
              textDecoration: 'none',
              transition: 'all 0.3s ease',
              whiteSpace: 'nowrap',
              '&:hover': {
                opacity: 0.8,
              },
            },
          }}
        >
          <Link target="_blank" href={Routes.PRIVACY} className="link">
            Privacy Policy
          </Link>
          <Link target="_blank" href={Routes.TERMS} className="link">
            Terms of use
          </Link>
          <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
            Cookie Policy
          </Link>
        </Box>
      </Box>
      <img
        draggable={false}
        src={footerImage}
        className="footer-image"
        alt="footer-img"
      />
    </Box>
  );
}

export default Footer;
